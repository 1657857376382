import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const WrapUpHair = () => (
  <Layout>
    <SEO
      title="Wrap Up Your Hair Day and Night"
      desciption="Wrapping up your hair serves two main purposes. One is to help retain moisture and the other is to help keep your hairstyle neat"
    />
    <h1>Wrap Up Your Hair Day and Night</h1>

    <p>Wrapping up your hair serves two main purposes. One is to help retain moisture and the other is to help keep your hairstyle neat.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/b0OW43J.jpg"
        alt="Wrap up your hair to keep it healthy"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@jacksondavid"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Jackson David</a></p>
    </div>

    <h2>During The Day</h2>
    <p>When it comes to wrapping your hair, it’s easy to think that this is something that is reserved for the night. We’ll get onto that later but it isn’t the only way. There is actually a whole movement/ argument around women wearing bonnets out in public or to post serious videos online. Some of us don’t like it and think a bonnet should only be seen by your own family and close friends in private. Others say, do what makes you comfortable and if you want to go to the shop in a bonnet then go ahead and feel confident doing it.</p>
    <p>Whichever side you are on, wearing hair protection during the day can help and it doesn’t have to be the bonnet you have reserved for around the house. There are fashionable head ties that are made from one straight piece of fabric. You have to use a bit of skill and imagination to tie it in a way that looks good. There are also options that are pre-wrapped so you don’t have to worry about tying them. Just pull it onto your head and get on with your day.</p>
    <p>The benefits are that they will protect your hair from harsh weather. Direct sunlight on a bright day can dry out your hair, damage your scalp and evaporate all of your hydration. Cold temperatures and freezing winds can make your hair hard and brittle making it far easier to break and become frizzy. Our hair is affected by temperature just as much as our skin. The difference is that with the skin, you can feel it straight away and make a change. With hair, you might not notice what’s going on until hours later, the end of the day or even a few days later if the environment is not extremely harsh.</p>

    <h2>During The Night</h2>
    <p>If you don’t wrap your hair during the day, that’s okay because with a good moisture control routine combined with protective styles at times is all you need. Wrapping the hair at night though is a whole different story.</p>
    <p>Wrapping your hair at night helps retain the moisture that would be lost to your pillow while you are asleep. I’m sure you know how bad a lack of moisture is for your hair. It will get dry, brittle and be more prone to breaking. If you are trying to grow long healthy hair then some kind of wrap at night is essential.</p>
    <p>You are at home in your own privacy so you can prioritise comfort over looks, it means you have far more options. There are bonnets with a wide elastic band and wrapped in a scrunchy version of the fabric. Some have an adjustable band to suit your size and there are different overall sizes so you can get one or more to fit the length of hair you have at the time.</p>
    <p>We’ve written a guide on silk & satin and types of bonnets so you can read it to get the full picture. The short version is that silk is the premium natural version which is soft and pretty much perfect for hair. Satin is the man made silk imitation that does most of what silk does for a fraction of the price. Both are good and which one you get depends on your budget.</p>
    <p>An alternative is to get a silk or satin pillow. The downside is that the moisture from your hair is going to sit on the pillowcase and touch your face during the night. If you have sensitive skin, your pores will get blocked and you will end up with spots around the sides of your face. Even with less sensitive skin, you might see a difference in skin condition after a while.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WrapUpHair
